<div class="container">
    <h3 class="page-title">
        Testimonial
    </h3>
    <div class="row">
        <div class="col-6 testimonial-card">
            <div class="text">
                <p><i class="fas fa-quote-left quote"></i>Lorem ipsum dolor sit amet consectetur elit. Ipsa voluptatum ipsum, laudantium minima aliquam porro? Iste, Et, esta ah, <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat fuga rem
                    perferendis dolor iusto, natus ea nulla obcaecati. <i class="fas fa-quote-right quote"></i>
                </p>
            </div>
            <div class="footer">
                <div class="image">
                </div>
                <h3 class='person'>Jed</h3>
            </div>
        </div>
        <div class="col-6 testimonial-card">
            <div class="text">
                <p><i class="fas fa-quote-left quote"></i>Lorem ipsum dolor sit amet consectetur elit. Ipsa voluptatum ipsum, laudantium minima aliquam porro? Iste, Et, esta ah, <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat fuga rem
                    perferendis dolor iusto, natus ea nulla obcaecati. <i class="fas fa-quote-right quote"></i>
                </p>
            </div>
            <div class="footer">
                <div class="image">
                </div>
                <h3 class='person'>Jed</h3>
            </div>
        </div>
    </div>
</div>