<!-- Header starts -->

<div class="container-fluid">

    <div class="row title-row">
        <div class="col-12 title-div">
            <p class="title-text">Donzelli</p>
        </div>
    </div>

    <div class="row logo-row">
        <div class="col-xs-6 col-sm-6 col-md-4 logo-div">
            <img class="logo" src="../assets/images/Donzelli-Landscaping-Logo.png" alt="Donzelli Landscaping" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-8 nav-div">
            <nav class='navbar navbar-expand-md navbar-light'><button class='navbar-toggler adjust-toggler active-toggler' type='button' data-toggle='collapse' data-target='#navbarCollapse'>
                <span class='navbar-toggler-icon'></span>
                </button>
                <div id='navbarCollapse' class='collapse navbar-collapse justify-content-between navbar-custom'>
                    <div class='navbar-nav'>
                        <a class='nav-item nav-link' id='home-a' [routerLink]="'/'">Home</a>
                        <a class='nav-item nav-link' id='what-we-do-a' [routerLink]="'about'">Services</a>
                        <a class='nav-item nav-link' id='testimonial-a' [routerLink]="'testimonial'">Testimonial</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>



<!-- Header ends -->


<!-- Page body starts -->

<router-outlet></router-outlet>

<!-- Page body ends -->


<!-- Footer starts -->





<!-- Footer ends -->