import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbCarousel, NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  ourVisionCarouselHeading = "Our Success is your Smile!";
  ourVisionCarouselText = "More importantly! We value your garden's happiness";

  @ViewChild('ourVisionCarousel', { static: true })
  ourVisionCarousel!: NgbCarousel;

  constructor(private titleService: Title, config: NgbCarouselConfig) { 

    config.interval = 6000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true; 
    config.pauseOnFocus = false;


  }

  ngOnInit(): void {
    
    this.titleService.setTitle("Donzelli - Your favorite gardener");    

  }

  onSlide(slideEvent: NgbSlideEvent) {
    console.log(slideEvent.current);
    if(slideEvent.current == "first-slide"){
      this.ourVisionCarouselHeading = "Our Success is your Smile!";
      this.ourVisionCarouselText = "More importantly! We value your garden's happiness";
    }
    else if(slideEvent.current == "second-slide"){
      this.ourVisionCarouselHeading = "Are you ready for the Zombies?";
      this.ourVisionCarouselText = "Not sure about you! But we have all the tools to fight the Zombies and maintain your yard.";
    }
  }

}
