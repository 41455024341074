<div class="container">
    <h3 class="page-title">
        Our Services
    </h3>
    <div class="row">
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
            <div class="card">
                <img src="https://gif-finder.com/wp-content/uploads/2017/06/Fire-Extinguisher-Balls.gif" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Blown away</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
            <div class="card">
                <img src="https://media.giphy.com/media/xT8qBv8bcQc4gcgAWA/giphy.gif" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Jolly</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
            <div class="card">
                <img src="https://media.giphy.com/media/dJZi221XtSnHW/giphy.gif" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Cool</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
            <div class="card">
                <img src="https://media.giphy.com/media/OWdtdLV2wYHjq/giphy.gif" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Dancing</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </div>
    </div>
</div>