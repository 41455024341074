<!-- Hero Banner starts -->

<div class="hero-banner-bg">
    <div class="container-fluid ">
        <div class="row hero-banner-row">
            <!-- <div class="col-xs-12 col-sm-12 col-md-6 hero-banner-img-div">
                <img class="hero-banner-img" src="../../../assets/images/home/lawn-is-happy.jpg" alt="Lawn is happy" />
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 hero-banner-heading-div">
                <p class="hero-banner-heading-text">Your lawn needs some caring too!</p>
                <p class="hero-banner-heading-text">And Donzelli does it with FUN!</p>
            </div>
        </div>
    </div>
</div>

<!-- Hero Banner ends -->

<!-- How we work starts -->

<div class="container-fluid">
    <div class="row how-we-work-heading-row">
        <div class="col-12 how-we-work-heading-div">
            <h2 class="how-we-work-heading-text">HOW WE WORK</h2>
        </div>
    </div>
    <div class="row how-we-work-row">
        <div class="col-xs-12 col-sm-12 col-md-6 how-we-work-img-div">
            <img class="how-we-work-img" src="../../../assets/images/home/how-we-work.jpg" alt="How we work" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 how-we-work-text-div">
            <h3>Our work is beyond expectations</h3>
            <p>Sometimes a bed close to nature is all the therapy you need!</p>
        </div>
    </div>
</div>

<!-- How we work ends -->


<!-- Meet the team starts -->

<div class="container-fluid">
    <div class="row meet-the-team-heading-row">
        <div class="col-12 meet-the-team-heading-div">
            <h2 class="meet-the-team-heading-text">MEET THE TEAM</h2>
        </div>
    </div>
    <div class="row meet-the-team-row">
        <div class="col-xs-12 col-sm-12 col-md-6 order-sm-12 meet-the-team-img-div">
            <img class="meet-the-team-img" src="../../../assets/images/home/meet-the-team.jpg" alt="Meet the Team" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 order-sm-1 meet-the-team-text-div">
            <h3>Oh! Not to forget.. Meet our experts..</h3>
            <p>It's tough to assemble a team of highly skilled experts under one roof.</p>
        </div>
             
    </div>
</div>

<!-- Meet the team ends -->


<!-- Our Vision starts -->

<div class="container-fluid">
    <div class="row our-vision-heading-row">
        <div class="col-12 our-vision-heading-div">
            <h2 class="our-vision-heading-text">OUR VISION</h2>
        </div>
    </div>
    <div class="row our-vision-row">
        <div class="col-xs-12 col-sm-12 col-md-6 our-vision-img-div">
            <ngb-carousel #ourVisionCarousel class="our-vision-carousel" (slide)="onSlide($event)">
                <ng-template ngbSlide id="first-slide">
                    <img class="our-vision-carousel-img" src="../../../assets/images/home/lawn-is-happy.jpg" alt="Lawn is Happy" />
                </ng-template>
                <ng-template ngbSlide id="second-slide">
                    <img class="our-vision-carousel-img" src="../../../assets/images/home/our-tools.jpg" alt="Our Tools" />
                </ng-template>
            </ngb-carousel>            
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 our-vision-text-div">
            <h3 class="our-vision-carousel-heading">{{ourVisionCarouselHeading}}</h3>
            <p class="our-vision-carousel-text">{{ourVisionCarouselText}}</p>
        </div>
              
    </div>
</div>

<!-- Our Vision ends -->